<template>
    <footer class="bg-default text-white pt-8 lg:pt-16 relative z-10">
      <div class="xl:container mx-auto px-8 xl:px-4">
        <div class="flex flex-col lg:flex-row">
          <div class="w-full lg:w-1/3 pe-0 sm:pe-16 mb-8 lg:mb-16">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.48 42.4" class="h-12 mb-4">
              <rect style="fill:none;" width="201.48" height="42.4"/>
              <g>
                <path style="fill: #fff;" d="M39.47,34.04h-2.69l-1.81-4.55c2.35-1.33,2.47-3.47,2.47-3.47,0,0,.15.26.46.84.56-1.72.22-2.67.06-3.57-.16-.89-1.09-3.29-1.1-3.32,0,0,0,0,.01,0-.42-1.1-.8-2.08-1.31-4.1-.38-1.52-.46-1.76-.3-1.96.22-.09.71.17,1.18-.16.45-.27,1.3-.67,1.65-.71.19.01.31.12.63.79,0,0,.76-.42.76-1.2-.04-1.66-1.94-1.56-2.54-1.57-.11-.17-.58-.47-1.09-.56h.01c-2.91-.51-7.95,1.32-7.95,1.32,0,0,.04.1.11.15.47.04,1.05.31,1.1.62.23,2.08-.64,5.37-.85,6.15-2.38-3.08-2.7-7.16-2.59-8.23C13.99,7.5,5.03,1.37,4.82,1.23c.04.07,1.13,2.07,2.88,4.36,3.78,4.3,11.91,5.15,13.7,5.66-8.59-1.29-9.92-2.27-12.42-3.32,1.31,1.76,2.56,2.95,4.65,3.91,2.34.92,6.28,1.04,8.17,1.11-4.07.21-7.53-.41-9.12-.68.37.38,1.82,1.52,2.74,1.96,1.05.41,6.46.27,6.46.27,0,0-3.01.25-6.12.32.36.43,1.76,1.23,1.91,1.26.66.19,4.28.12,4.28.12,0,0-1.55.17-3.65.27.31.19,2.56,1.35,3.86,1.93.28.16.45.35.56.54-1.85.36-4.11,1.07-6.91,2.32-6.21,2.93-8.8,4.79-10.77,4-.87-.37-1.36-1.58-1.36-2.35.63-.88,1.67-3.57-1.99-5.34.32.67.4,1.08.35,1.42-.05.35-.82,3.06.95,4.05.15.9.22,3.83,3.28,3.8,2.55-.2,4.22-1.69,8.68-3.27-.38.45-.64.88-.85,1.24-2.01,3.42-5.17,3.06-5.82,3.05-.07.67-.64,1.26-1.19,2.14v.02c-1.66,2.08.45,5.81.45,5.81h7.48c-.35-2.53-1.55-2.58-2.09-2.69-.45-.05-1.18.06-1.97.05-.77-.11-.77-.73-.72-1.04.53-1.46,2.38-1.63,4.59-1.84,2.4-.24,3.43-.72,4.84-1.4.88,1.54-.41,2.65-.69,2.98.57,1.92,1.81,2.27,2.92,2.6,1.84.37,3.14.3,5.98.3-.32-2.53-2.24-2.4-2.72-2.4-1.32.14-1.52.06-1.76,0-1.21-.36-.87-1.36-.63-1.52,4.8-1.86,4.89-3.31,5.52-5.08.71,2.34,2.8,3.13,3.19,3.43.63.47.28.95.15,1.24.58-.06,1.08-.16,1.55-.28.4,1.16,1.05,2.95,1.58,3.89-2.2-.04-2.51,1.73-2.51,1.73h6.56c.87.09,1.5,1.05,1.5,1.4,1.21-.57.84-1.37.84-1.37h1.11c-1.36-2.72-2.8-1.79-2.8-1.79ZM34.29,11.17l.98.31s1.02-.17.92,0h-.01s-.63.53-1.02.56c-.48.04-1.14-.88-.87-.87Z"/>
                <path style="fill: #fff;" d="M43.98,32.24c-1.29-2.3-2.5-1.44-2.5-1.44l-2.34.11-1.63-2.71s-.38.93-.84,1.39c-.09.07.11.41.72,1.44-1.91.05-.97,1.55-.97,1.55,0,0,3.75-.17,4.56-.21.76.04,1.35.85,1.36,1.15,1.04-.54.68-1.23.68-1.23,0,0,.07-.01.97-.05Z"/>
                <polygon style="fill: #fff;" points="4.8 1.21 4.82 1.23 4.82 1.21 4.8 1.21"/>
              </g>
              <g class="logo">
                <path style="fill: #fff;" d="M56.6,24.72h4.59v2.53c0,2.36-2.3,3.44-4.43,3.44s-4.42-1.08-4.42-3.44v-6.97c0-2.3,2.27-3.35,4.37-3.35,1.83,0,3.29.68,3.92,1.81l.16.28,1.02-.67-.16-.26c-.89-1.44-2.77-2.33-4.9-2.33-2.76,0-5.57,1.55-5.57,4.51v6.97c0,3.14,2.88,4.57,5.57,4.57s5.58-1.57,5.58-4.57v-3.66h-5.74v1.15Z"/>
                <path style="fill: #fff;" d="M75.11,20.36c-.06-2.86-1.78-4.37-4.98-4.37h-5.61v15.62h1.13v-6.7h2.95l5.04,6.7h1.39l-5.06-6.7h.19c3.24,0,4.95-1.57,4.95-4.56h.01ZM73.15,17.99c.54.54.83,1.35.83,2.4,0,2.38-1.18,3.44-3.83,3.44h-4.51v-6.73h4.51c1.37-.02,2.36.27,3,.89Z"/>
                <path style="fill: #fff;" d="M78.21,15.99h-1.15v15.62h1.15v-15.62Z"/>
                <path style="fill: #fff;" d="M81.11,31.62h1.15v-6.96h7.37v-1.14h-7.37v-6.38h8.11v-1.15h-9.26v15.62Z"/>
                <path style="fill: #fff;" d="M97.46,15.78c-2.83,0-5.88,1.42-5.88,4.54v6.93c0,3,2.96,4.57,5.88,4.57s5.91-1.57,5.91-4.57v-6.93c0-3.12-3.06-4.54-5.91-4.54ZM97.46,30.69c-2.28,0-4.73-1.06-4.73-3.42v-6.93c0-2.5,2.54-3.4,4.72-3.42,2.2,0,4.78.89,4.78,3.42v6.93c0,2.35-2.46,3.42-4.75,3.42h-.01Z"/>
                <path style="fill: #fff;" d="M118.53,28.15c-1.25,2.44-3.52,3.37-5.41,3.37-2.95,0-5.92-1.72-5.92-5.13v-5.17c0-3.39,2.97-5.13,5.98-5.13,1.85,0,4.07.78,5.27,3.45l-3.1,1.25c-1.01-1.87-4.37-1.44-4.37.42v5.17c0,1.81,3.39,2.32,4.22.24l3.31,1.52h.01Z"/>
                <path style="fill: #fff;" d="M124.01,26.54h-.68v4.77h-3.84v-15h6c3.1,0,5.49,1.42,5.57,4.94,0,2.77-1.13,4.35-2.95,4.91l4.08,5.15h-4.73l-3.48-4.77h.01ZM125.55,23.27c2.39,0,2.39-3.43,0-3.43h-2.23v3.43h2.23Z"/>
                <path style="fill: #fff;" d="M136.6,22.2h6.08v3.28h-6.08v2.24h6.61v3.58h-10.45v-15h10.45v3.57h-6.61v2.33h0Z"/>
                <path style="fill: #fff;" d="M157.54,31.31h-4.07l-.98-3.01h-4.01l-.93,3.01h-4.07l5.24-15h3.45l5.36,15h.01ZM151.59,24.93l-1.04-4.38h-.11l-1.1,4.38h2.25Z"/>
                <path style="fill: #fff;" d="M163.44,19.88v11.43h-3.84v-11.43h-3.99v-3.6h11.81v3.6h-3.99Z"/>
                <path style="fill: #fff;" d="M172.27,31.31h-3.79v-15h3.79v15Z"/>
                <path style="fill: #fff;" d="M183.06,16.28h4.07l-5.41,15h-3.48l-5.41-15h4.07l2.35,7.33.68,3.57h.06l.68-3.54,2.38-7.35Z"/>
                <path style="fill: #fff;" d="M186.93,21.24c0-3.45,3.09-5.15,6.21-5.15s6.23,1.72,6.23,5.15v5.13c0,3.43-3.1,5.15-6.21,5.15s-6.23-1.72-6.23-5.15v-5.13ZM190.72,26.37c0,1.1,1.23,1.63,2.44,1.63s2.41-.54,2.41-1.63v-5.13c0-1.16-1.25-1.7-2.48-1.7s-2.38.59-2.38,1.7v5.13Z"/>
              </g>
            </svg>
            <h5 class="text-title-6 lg:text-title-5 leading-title-6 lg:leading-title-5 font-extrabold tracking-compress-5 text-white opacity-80">Creatividad en el cielo, con los pies sobre la tierra</h5>
          </div>
          <div class="w-full lg:w-2/3">
            <div class="flex flex-col sm:flex-row">
              <div class="w-full sm:w-1/3 mb-6 sm:mb-0">
                <p class="text-paragraph-18 tracking-wide text-white mb-1">Contacto</p>
                <ul>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="mailto:info@grifocreativo-com">info@grifocreativo.com</a>
                  </li>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="tel:5492302207390">+54 9 2302 207390</a>
                  </li>
                  <li class="py-1 lg:py-2 flex gap-4 mt-2">
                    <a class="text-paragraph-14 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="https://www.linkedin.com/company/grifocreativo" target="_blank">
                      <img src="@/assets/images/icons/icon-linkedin.svg" alt="Linkedin" rel="nofollow"/>
                    </a>
                    <a class="text-paragraph-14 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="https://www.facebook.com/grifocreativo" target="_blank">
                      <img src="@/assets/images/icons/icon-facebook.svg" alt="Facebook" rel="nofollow"/>
                    </a>
                    <a class="text-paragraph-14 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="https://www.instagram.com/grifo_creativo/" target="_blank">
                      <img src="@/assets/images/icons/icon-instagram.svg" alt="Instagram" rel="nofollow"/>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="w-full sm:w-1/3 mb-6 sm:mb-0">
                <p class="text-paragraph-18 tracking-wide text-white mb-1">Sobre Nosotros</p>
                <ul>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="/nosotros">El equipo</a>
                  </li>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="/contacto">Contacto</a>
                  </li>
                </ul>
              </div>
              <div class="w-full sm:w-1/3">
                <p class="text-paragraph-18 tracking-wide text-white mb-1">Soluciones</p>
                <ul>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="/grifo-crm-whatsapp">Grifo CRM + Whatsapp</a>
                  </li>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="/">Plataforma Odoo</a>
                  </li>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="/crm-personalizado">CRM personalizado</a>
                  </li>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="/desarrollo-web">Diseño y desarrollo web</a>
                  </li>
                  <li class="py-1 lg:py-2">
                    <a class="text-paragraph-14 lg:text-paragraph-16 text-white tracking-wide font-light opacity-70 hover:opacity-100 transition cursor-pointer" href="/desarrollo-software">Software a medida</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center items-center mt-8 lg:mt-16 copyright py-4">
          <p class="text-paragraph-14 text-white tracking-wide opacity-70 font-light">© 2024 Grifo Creativo. Todos los derechos reservados</p>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
  };
  </script>
  
  <style scoped>
    .copyright{
      border-top:1px solid rgba(255, 255, 255, 0.2);
    }
  </style>
  