<template>
    <nav class="main-menu">
        <ul class="flex items-stretch gap-8">
            <li class="relative flex items-center group">
                <span  
                @mouseover="openDropdown('servicios')" 
                @mouseleave="startCloseDropdownTimer('servicios')" 
                class="text-menu transition duration-300 flex items-center"
                >
                    Servicios
                    <svg class="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </span>
                <ul 
                    ref="servicios" 
                    v-show="dropdownOpen === 'servicios'" 
                    @mouseover="cancelCloseDropdownTimer()" 
                    @mouseleave="startCloseDropdownTimer()" 
                    class="gc-dropdown absolute left-0 mt-2 w-64 bg-white rounded-md shadow-lg z-20"
                    >
                    <li><a href="/crm-personalizado" class="block p-4 hover:bg-gray-100">CRM personalizado</a></li>
                    <li><a href="/desarrollo-web" class="block p-4 hover:bg-gray-100">Diseño y desarrollo web</a></li>
                    <li><a href="/desarrollo-software" class="block p-4 hover:bg-gray-100">Software a medida</a></li>
                </ul>
            </li>
            <li class="relative flex items-center group">
                <span
                @mouseover="openDropdown('software')" 
                @mouseleave="startCloseDropdownTimer('software')" 
                class="text-menu transition duration-300 flex items-center"
                >
                    Software
                    <svg class="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </span>
                <ul 
                    ref="software" 
                    v-show="dropdownOpen === 'software'" 
                    @mouseover="cancelCloseDropdownTimer()" 
                    @mouseleave="startCloseDropdownTimer()" 
                    class="gc-dropdown absolute left-0 mt-2 w-56 bg-white text-gray-800 rounded-md shadow-lg z-20"
                    >
                    <li><a href="/grifo-crm-whatsapp" class="block p-4 hover:bg-gray-100">Grifo CRM + Whatsapp</a></li>
                    <!--<li><a href="#" class="block p-4 hover:bg-gray-100">Plataforma Odoo</a></li>-->
                </ul>
            </li>
            <li class="flex items-center"><a href="/casos-exito" class="text-menu transition duration-300">Casos de éxito</a></li>
            <li class="flex items-center"><a href="/nosotros" class="text-menu transition duration-300">Nosotros</a></li>
            <li>
                <a href="/contacto" class="button button-white text-secondary button-medium button-icon">
                    Contacto
                    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4872 1L15.5 6M15.5 6L10.4872 11M15.5 6L1.5 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </li>
        </ul>
    </nav>
    <navMenuMobileComponent @toggle-menu="handleToggleMenu"/>
</template>

<script>
import { ref } from 'vue';
import NavMenuMobileComponent from '@/components/parts/NavMenuMobileComponent.vue';

export default {
  name: 'NavMenuComponent',
  components: {
    NavMenuMobileComponent,
  },
  emits: ['toggle-menu'], // Declarar el evento que este componente puede emitir
  setup() {
    const dropdownOpen = ref(null);
    let closeDropdownTimer = null;

    const handleToggleMenu = (isActive) => {
      if (isActive) {
        document.body.classList.add('menu-activo');
      } else {
        document.body.classList.remove('menu-activo');
      }
    };

    const openDropdown = (menu) => {
      clearTimeout(closeDropdownTimer);
      dropdownOpen.value = menu;
    };

    const startCloseDropdownTimer = () => {
      closeDropdownTimer = setTimeout(() => {
        dropdownOpen.value = null;
      }, 200); // Ajuste el retraso según sea necesario
    };

    const cancelCloseDropdownTimer = () => {
      clearTimeout(closeDropdownTimer);
    };

    return {
      dropdownOpen,
      openDropdown,
      startCloseDropdownTimer,
      cancelCloseDropdownTimer,
      handleToggleMenu,
    };
  },
};
</script>


<style scoped>
.gc-dropdown {
    top: 100%;
    overflow: hidden;
}
@media(max-width:1023px) {
    .main-menu {
        display: none;
    }
}
.text-menu{
  cursor: pointer;
}
.text-menu:hover{
  color: #00A23A!important;
}
</style>
