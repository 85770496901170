<template>
    <button 
        :class="{'scroll-button': true, 'visible': isVisible}" 
        @click="scrollToTop">
        <svg width="24" height="46" viewBox="0 0 24 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 13.1522L12 2.5M12 2.5L22.5 13.1522M12 2.5L12 44.5" stroke="#25282E" stroke-width="3" stroke-linecap="round"/>
        </svg>
    </button>
  </template>
  
  <script>
export default {
  name: 'ButtonScrollComponent',
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isVisible = window.scrollY >= 1000;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
    .scroll-button {
    position: fixed;
    bottom: 100px;
    right: 34px;
    border: none;
    cursor: pointer;
    opacity: 0;
    width: 24px;
    transition: opacity 0.5s ease-in-out;
    mix-blend-mode: difference;
    z-index: 99;
    }
    .scroll-button svg {
    width: 100%;
    }
    .scroll-button svg path{
        fill:black;
    }
    .scroll-button.visible {
    opacity: 1;
    }
</style>