<template>
        <div class="wa-wrapper fixed bottom-4 right-4 transition-all"> 
            <a :href="whatsAppLink" target="_blank" rel="noopener noreferrer" class="wa-icon bg-green-500 text-white p-3 rounded-full shadow-lg flex items-center justify-center">
                <svg width="32" lass="fill-current" height="32" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5818 11.9851C14.333 11.8612 13.1097 11.2622 12.8816 11.1795C12.6536 11.0968 12.4877 11.0556 12.3218 11.3034C12.1559 11.5513 11.6791 12.1091 11.5339 12.2744C11.3888 12.4396 11.2436 12.4604 10.9948 12.3363C10.746 12.2124 9.94426 11.951 8.99387 11.1072C8.25422 10.4506 7.7548 9.63972 7.60969 9.39181C7.46453 9.1439 7.59418 9.0099 7.71875 8.88643C7.83074 8.77548 7.96762 8.59719 8.09199 8.45257C8.21644 8.30803 8.25789 8.20467 8.34082 8.03948C8.42375 7.87418 8.3823 7.7296 8.32004 7.60567C8.25789 7.48173 7.76027 6.26283 7.55293 5.76693C7.35094 5.28413 7.14582 5.34952 6.99309 5.34186C6.84812 5.33471 6.68203 5.33319 6.51621 5.33319C6.35035 5.33319 6.08074 5.39516 5.85266 5.64299C5.62461 5.8909 4.98184 6.49005 4.98184 7.70884C4.98184 8.92779 5.8734 10.1052 5.99781 10.2705C6.12223 10.4358 7.75234 12.937 10.2484 14.0096C10.842 14.2648 11.3055 14.4171 11.6669 14.5312C12.2629 14.7198 12.8054 14.6932 13.2341 14.6294C13.7121 14.5583 14.7062 14.0304 14.9136 13.452C15.1209 12.8734 15.1209 12.3777 15.0587 12.2744C14.9965 12.1711 14.8307 12.1091 14.5818 11.9851ZM10.0418 18.1544H10.0385C8.55324 18.1538 7.09656 17.7567 5.82578 17.0062L5.52352 16.8277L2.39094 17.6455L3.22707 14.6059L3.03031 14.2942C2.20176 12.9827 1.76414 11.4669 1.7648 9.91045C1.76664 5.3688 5.47965 1.67387 10.0451 1.67387C12.2559 1.67461 14.334 2.53255 15.8967 4.08956C17.4594 5.64653 18.3195 7.71611 18.3186 9.9171C18.3168 14.4591 14.6038 18.1544 10.0418 18.1544ZM17.0861 2.90646C15.2059 1.0332 12.7057 0.00108852 10.0417 0C4.55289 0 0.0856641 4.44558 0.0834375 9.90983C0.0827344 11.6566 0.54125 13.3614 1.41273 14.8645L0 20L5.27895 18.6219C6.73348 19.4114 8.37109 19.8276 10.0378 19.8281H10.0419C15.5301 19.8281 19.9977 15.3821 20 9.91776C20.001 7.26967 18.9661 4.77972 17.0861 2.90646Z" fill="white"/>
                </svg>
            </a>
            <span class="wa-dialog rounded-md p-4 absolute shadow-lg transition-all">
                <p class="font-extrabold tracking-compress">Estamos para ayudarte.</p>
                <p class="text-paragraph-14 tracking-wide">¿Tenés alguna duda o consulta?, escribinos.</p>
            </span>
        </div>
  </template>
  
  <script>
  export default {
    name: 'ButtonWhatsappComponent',
    props: {
      phoneNumber: {
        type: String,
        required: true
      },
      message: {
        type: String,
        default: ''
      }
    },
    computed: {
      whatsAppLink() {
        const baseLink = 'https://wa.me/';
        const encodedMessage = encodeURIComponent(this.message);
        return `${baseLink}${this.phoneNumber}?text=${encodedMessage}`;
      }
    }
  };
  </script>
  
  <style scoped>
    .wa-wrapper{
        opacity:0.8;
        z-index:99;
    }
    .wa-wrapper:hover{
        opacity:1;
    }
    .wa-icon:hover + .wa-dialog{
        opacity:1;
    }
    .wa-dialog{
        opacity:0;
        right:70px;
        bottom:0;
        width:340px;
        background-color:rgba(255, 255, 255, 1);
    }
    .wa-dialog::after{
        content:"";
        left: 100%;
        top: 70%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #ffffff;
        border-width: 8px;
        margin-top: -8px;
    }
    body.menu-activo .wa-wrapper{
      display: none!important;
    }
  </style>
  