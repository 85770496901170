<template>
    <div class="menu-mobile absolute flex items-center">
        <div class="lets-talk">
            <a href="/contact" class="text-menu">HABLEMOS.</a>
        </div>
        <div class="menu-toggle" @click="toggleMenu">
            <div :class="['menu-icon', { active: isActive }]">
                <span></span>
            </div>
            <div :class="['menu-wrapper bg-default', { active: isActive }]">
                <ul class="nav-menu">
                    <li><a href="#" class="text-title-4 sm:text-title-3 md:text-title-2 text-white tracking-compress font-extrabold">Inicio</a></li>
                    <li class="submenu">
                        <a href="#" class="text-title-4 sm:text-title-3 md:text-title-2 text-white tracking-compress font-extrabold">Servicios</a>
                        <ul class="nav-submenu flex flex-col md:flex-row">
                            <li><a href="/crm-personalizado" class="text-paragraph-16 sm:text-text-paragraph-18 md:text-text-paragraph-20 text-white tracking-compress font-bold">CRM personalizado</a></li>
                            <li><a href="/desarrollo-web" class="text-paragraph-16 sm:text-text-paragraph-18 md:text-text-paragraph-20 text-white tracking-compress font-bold">Diseño y desarrollo web</a></li>
                            <li><a href="/desarrollo-software" class="text-paragraph-16 sm:text-text-paragraph-18 md:text-text-paragraph-20 text-white tracking-compress font-bold">Software a medida</a></li>
                        </ul>
                    </li>
                    <li class="submenu">
                        <a href="#" class="text-title-4 sm:text-title-3 md:text-title-2 text-white tracking-compress font-extrabold">Software</a>
                        <ul class="nav-submenu flex flex-col md:flex-row">
                            <li><a href="/grifo-crm-whatsapp" class="text-paragraph-18 sm:text-text-paragraph-20 text-white tracking-compress font-bold">Grifo CRM + Whatsapp</a></li>
                            <!--<li><a href="#" class="text-paragraph-18 sm:text-text-paragraph-20 text-white tracking-compress font-bold">Plataforma Odoo</a></li>-->
                        </ul>
                    </li>
                    <li><a href="/casos-exito" class="text-title-4 sm:text-title-3 md:text-title-2 text-white tracking-compress font-extrabold">Casos de éxito</a></li>
                    <li><a href="/nosotros" class="text-title-4 sm:text-title-3 md:text-title-2 text-white tracking-compress font-extrabold">Nosotros</a></li>
                    <li><a href="/contacto" class="text-title-4 sm:text-title-3 md:text-title-2 text-white tracking-compress font-extrabold">Contacto</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'NavMenuMobileComponent',
  emits: ['toggle-menu'],
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
      this.$emit('toggle-menu', this.isActive);
    },
  },
};
</script>

<style scoped>
@media(max-width:425px){
    .lets-talk{
        display: none;
    }
}

.menu-mobile{
    display:none;
    width:auto;
    right:32px;
    gap:16px;
}
@media(max-width:1023px){
    .menu-mobile{
        display:flex;
    }
}
@media(max-width:639px){
    .menu-mobile{
        right:16px;  
    } 
}
.menu-toggle{
    width:44px;
    position:relative;
    height:44px;
    overflow: hidden;
}
.menu-wrapper{
    opacity:0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    height: 100vh;
    width: 100%;
    clip-path: circle(25px at calc(100% - 45px) 45px);
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.menu-wrapper .nav-menu{
    padding:0 32px;
    list-style: none;
    text-align: left;
}
.menu-wrapper .nav-menu:hover > li:not(:hover){
    filter: blur(1px);
}
@media(max-width:375px){
    .menu-wrapper .nav-menu{
        margin-top: 64px;
    }
}
.menu-wrapper .nav-menu>li{
    margin:5px 0;
    opacity: .65;
    transition: all 0.3s ease-in-out;
    top: 0;
    left: 0;
    position: relative;
}
.menu-wrapper .nav-menu>li:hover{
    opacity: 1;
    top: -5px;
    left: 5px;
}
.menu-wrapper .nav-menu>li>a{
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    display: block;
    line-height: 1.2;
    letter-spacing: -2px;
}
@media(max-width:640px){
    .menu-wrapper .nav-menu>li>a{
        letter-spacing: -1px;
    }   
}
.menu-wrapper .nav-menu>li>.nav-submenu{
    padding-left:200px;
    gap:8px 32px;
    padding-left:32px;
    opacity: 1;
    margin-bottom: 16px;
}
@media(max-width:767px){
    .menu-wrapper .nav-menu>li>.nav-submenu{
        padding:16px 0 16px 32px;
    }
}
.menu-wrapper .nav-menu>li>.nav-submenu>li{
    opacity: .75;
    transition: all 0.3s ease-in-out;
}
.menu-wrapper .nav-menu>li>.nav-submenu>li:hover{
    opacity: 1;
}
.menu-wrapper .nav-menu>li>.nav-submenu>li>a{
    line-height: 1.2;
    text-transform: uppercase;
    position: relative;
}
.menu-wrapper .nav-menu>li>.nav-submenu>li>a::after{
    content:"/";
    position:absolute;
    left:calc(100% + 10px);
    top: -4px;
    font-weight: 300;
    font-size: 24px;
}
.menu-wrapper .nav-menu>li>.nav-submenu>li:last-child>a::after{
    display: none;
}
@media(max-width:767px){
    .menu-wrapper .nav-menu>li>.nav-submenu>li>a::after{
        display: none;
    }  
}
.menu-wrapper .nav-menu>li>.nav-submenu>li:hover{
    opacity: 1;
}

.menu-icon {
    width: 44px;
    height: 44px;
    border-radius:100%;
    display:inline-block;
    cursor:pointer;
    padding:12px 12px;
    background-color: #fff;
    position: absolute;
    z-index:99;
    transition: all 0.3s ease-in-out;
}
.menu-icon:after, 
.menu-icon:before, 
.menu-icon span {
    background-color: #392C5B;
    border-radius: 3px;
    content: '';
    display: block;
    height: 2px;
    margin: 4px 0;
    transition: all .2s ease-in-out;
}
.menu-icon.active {
    background-color: #fff;
}
.menu-icon.active:before {
    transform: translateY(6px) rotate(135deg);
    background-color:#25282E;
}
.menu-icon.active:after {
    transform: translateY(-6px) rotate(-135deg);
    background-color:#25282E;
}
.menu-icon.active span {
    transform: scale(0);
}
.menu-wrapper.active {
    opacity:1;
    clip-path: circle(75%);
}
</style>
