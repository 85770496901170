<template>
  <header :class="['gc-header fixed w-full top-0 z-50', headerClass]" id="header">
    <div class="xl:container mx-auto">
      <div class="flex justify-between items-center py-5 px-4 sm:px-8 xl:px-4 relative">
        <router-link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.48 42.4" class="dynamic-brand h-11">
            <rect style="fill:none;" width="201.48" height="42.4"/>
            <g>
              <path d="M32.5391 26.7617L34.8482 32.5558H37.5186C37.5186 32.5558 38.9508 31.6363 40.2948 34.3288H39.1931C39.1931 34.3288 39.5633 35.1251 38.3559 35.697C38.3559 35.3494 37.7213 34.3903 36.8664 34.3112H30.349C30.349 34.3112 30.6574 32.5558 32.8431 32.5998C32.0235 31.1524 30.913 27.6636 30.913 27.6636L32.5435 26.7661L32.5391 26.7617Z" fill="#00A23A"/>
              <path d="M35.5751 26.7617L37.2011 29.4542L39.519 29.3486C39.519 29.3486 40.7221 28.4951 42 30.7784C41.1143 30.818 41.0438 30.8224 41.0438 30.8224C41.0438 30.8224 41.3963 31.4999 40.3739 32.0411C40.3607 31.7419 39.7746 30.9368 39.0255 30.8972C38.2279 30.9324 34.4999 31.1084 34.4999 31.1084C34.4999 31.1084 33.5656 29.6258 35.4605 29.573C34.8568 28.5611 34.6673 28.2223 34.7422 28.1475C35.2049 27.6944 35.5751 26.7705 35.5751 26.7705V26.7617Z" fill="#00A23A"/>
              <path d="M5.36309 28.557C5.90511 27.6859 6.46916 27.0964 6.54848 26.4365C7.19186 26.4541 10.3338 26.7972 12.3256 23.4141C13.0351 22.213 14.2866 20.2201 18.9136 19.1862C23.5406 18.1523 26.4358 17.5144 26.4358 17.5144L27.013 15.5479H33.7024C33.7376 15.7722 33.9007 15.9834 33.9007 16.177C33.9007 16.8589 34.9186 18.6143 34.9186 18.6143C34.9186 18.6143 35.8484 21.0208 36.0159 21.905C36.1789 22.7937 36.5094 23.7308 35.9498 25.4378C35.6369 24.8615 35.4871 24.5975 35.4871 24.5975C35.4871 24.5975 35.2711 28.4426 29.7231 29.0013C29.8553 28.7242 30.1991 28.2446 29.5733 27.7695C29.1767 27.4747 27.1056 26.6828 26.3961 24.3687C25.7704 26.1285 25.6866 27.5583 20.9142 29.3973C20.6851 29.5601 20.3369 30.5456 21.54 30.9107C21.7691 30.9767 21.9674 31.0427 23.285 30.9107C23.7609 30.9107 25.6646 30.7919 25.9951 33.2908C23.1792 33.2908 21.8881 33.3656 20.0593 33.0004C18.9577 32.6705 17.7238 32.3273 17.1597 30.418C17.4374 30.088 18.7241 28.9882 17.8516 27.4571C16.4503 28.1303 15.4323 28.6098 13.0439 28.8386C10.8406 29.0541 9.01181 29.2169 8.48301 30.6643C8.43454 30.9767 8.43454 31.5838 9.19248 31.7026C9.98128 31.7202 10.7084 31.6014 11.1534 31.6542C11.6955 31.7686 12.8809 31.817 13.229 34.3159H5.80376C5.80376 34.3159 3.71059 30.6203 5.35868 28.5614L5.36309 28.557Z" fill="#0094D2"/>
              <path d="M33.9093 9.22471C34.4116 9.3127 34.8832 9.60307 34.9889 9.78344C35.5794 9.80104 37.4699 9.69546 37.5051 11.3452C37.5051 12.1195 36.7472 12.5287 36.7472 12.5287C36.4255 11.8644 36.2977 11.7544 36.1214 11.7368C35.7777 11.772 34.936 12.1679 34.4822 12.4363C34.015 12.7663 33.5303 12.5067 33.3144 12.5991C33.1513 12.7971 33.2307 13.0302 33.614 14.5392C34.1208 16.5366 34.4998 17.5133 34.9184 18.6087C33.0852 18.9871 30.547 19.2642 26.321 17.5749C26.321 17.5749 27.4711 13.6594 27.22 11.31C27.1759 10.9977 26.5898 10.7337 26.1227 10.6985C26.0522 10.6457 26.0169 10.5533 26.0169 10.5533C26.0169 10.5533 31.0141 8.74078 33.9093 9.22471Z" fill="#0094D2"/>
              <path d="M3.08038 0C3.08038 0 12.0612 6.19442 23.8049 9.24763C23.6815 10.4487 24.0825 15.4552 27.3964 18.5349C24.3602 17.8617 20.3677 19.3487 20.3677 19.3487C20.3677 19.3487 21.8131 17.9321 20.3237 17.0566C19.0281 16.4759 16.7895 15.3277 16.4855 15.1473C18.5698 15.0549 20.1121 14.8833 20.1121 14.8833C20.1121 14.8833 16.5119 14.9449 15.8553 14.7557C15.7055 14.7117 14.3262 13.933 13.956 13.5063C17.0451 13.4359 20.0284 13.1851 20.0284 13.1851C20.0284 13.1851 14.6611 13.3171 13.6167 12.9124C12.7089 12.4856 11.2636 11.3594 10.8978 10.9722C12.4798 11.2362 15.917 11.8565 19.9579 11.6497C18.0807 11.5749 14.1631 11.4517 11.8408 10.5411C9.76529 9.58638 8.52261 8.40294 7.21383 6.66076C9.69038 7.70782 11.0124 8.6669 19.5481 9.94714C17.759 9.45 9.68156 8.60091 5.9403 4.33345C4.16882 2.02374 3.08038 0.00439944 3.08038 0.00439944V0Z" fill="#00A23A"/>
              <path d="M1.97863 21.5142C1.96982 22.2753 2.46777 23.4808 3.33148 23.8459C5.28363 24.6378 7.85713 22.79 14.0221 19.882C20.2443 17.0972 23.7916 17.0312 26.0126 17.4271C26.4665 17.5503 28.0925 19.2441 28.7315 19.7984C29.3528 20.3879 29.7406 20.7663 30.0403 21.1139C28.3349 20.4935 24.9991 19.2793 19.6141 20.4319C9.86216 22.4205 8.04221 25.1437 4.54772 25.4121C1.51153 25.4297 1.43221 22.5349 1.2912 21.6462C-0.467064 20.6695 0.290882 17.9814 0.343762 17.6339C0.396642 17.2863 0.321729 16.886 4.28314e-05 16.2305C3.63995 17.9814 2.60879 20.6519 1.97863 21.5142Z" fill="#00A23A"/>
              <path d="M32.4156 12.7984C32.4156 12.7984 32.5742 15.6272 34.3413 19.5735C35.1169 21.3245 35.2579 22.9963 35.161 23.577C34.3898 22.3715 34.0725 22.178 34.0725 22.178C34.0725 22.178 35.0728 26.1111 30.8689 27.9456C32.0675 26.4234 31.8956 22.4595 30.7411 20.8053C29.5821 19.3535 27.1717 17.3738 27.1717 17.3738C27.1717 17.3738 27.815 16.3443 28.3438 15.9747C28.0045 16.0407 27.392 16.3927 27.392 16.3927C27.392 16.3927 28.075 12.9699 28.6567 12.1692C28.335 12.156 27.7181 12.3408 27.7181 12.3408C27.7181 12.3408 28.0574 10.603 29.5513 10.3127C31.0495 10.0223 32.4905 10.6646 32.4905 10.6646L32.4112 12.7984H32.4156Z" fill="#00A23A"/>
              <path d="M29.0092 10.5014C30.4502 9.77992 33.2485 9.47636 34.3369 9.88111C34.5793 10.0263 34.8525 10.2331 34.6806 10.8578C34.5969 11.4165 34.1342 11.7113 33.46 11.8081C32.7814 11.9049 31.4109 11.4737 31.4109 11.4737" fill="#00A23A"/>
              <path d="M34.2619 10.1797C34.3589 10.0037 33.3586 10.1797 33.3586 10.1797L32.3891 9.87612C32.1247 9.86292 32.7637 10.7648 33.2528 10.7384C33.6362 10.7164 34.2619 10.1753 34.2619 10.1753" fill="#392C5B"/>
              <path d="M26.5635 20.8189C26.5635 20.8189 19.4423 20.467 13.6167 23.045C12.5547 25.4119 10.3293 26.6306 10.3293 26.6306C10.3293 26.6306 14.1675 27.5809 17.6708 24.3957C21.1741 21.2149 24.3866 21.4656 26.5591 20.8189H26.5635Z" fill="#392C5B"/>
            </g>
            <g class="logo">
              <path d="M56.6,24.72h4.59v2.53c0,2.36-2.3,3.44-4.43,3.44s-4.42-1.08-4.42-3.44v-6.97c0-2.3,2.27-3.35,4.37-3.35,1.83,0,3.29.68,3.92,1.81l.16.28,1.02-.67-.16-.26c-.89-1.44-2.77-2.33-4.9-2.33-2.76,0-5.57,1.55-5.57,4.51v6.97c0,3.14,2.88,4.57,5.57,4.57s5.58-1.57,5.58-4.57v-3.66h-5.74v1.15Z"/>
              <path d="M75.11,20.36c-.06-2.86-1.78-4.37-4.98-4.37h-5.61v15.62h1.13v-6.7h2.95l5.04,6.7h1.39l-5.06-6.7h.19c3.24,0,4.95-1.57,4.95-4.56h.01ZM73.15,17.99c.54.54.83,1.35.83,2.4,0,2.38-1.18,3.44-3.83,3.44h-4.51v-6.73h4.51c1.37-.02,2.36.27,3,.89Z"/>
              <path d="M78.21,15.99h-1.15v15.62h1.15v-15.62Z"/>
              <path d="M81.11,31.62h1.15v-6.96h7.37v-1.14h-7.37v-6.38h8.11v-1.15h-9.26v15.62Z"/>
              <path d="M97.46,15.78c-2.83,0-5.88,1.42-5.88,4.54v6.93c0,3,2.96,4.57,5.88,4.57s5.91-1.57,5.91-4.57v-6.93c0-3.12-3.06-4.54-5.91-4.54ZM97.46,30.69c-2.28,0-4.73-1.06-4.73-3.42v-6.93c0-2.5,2.54-3.4,4.72-3.42,2.2,0,4.78.89,4.78,3.42v6.93c0,2.35-2.46,3.42-4.75,3.42h-.01Z"/>
              <path d="M118.53,28.15c-1.25,2.44-3.52,3.37-5.41,3.37-2.95,0-5.92-1.72-5.92-5.13v-5.17c0-3.39,2.97-5.13,5.98-5.13,1.85,0,4.07.78,5.27,3.45l-3.1,1.25c-1.01-1.87-4.37-1.44-4.37.42v5.17c0,1.81,3.39,2.32,4.22.24l3.31,1.52h.01Z"/>
              <path d="M124.01,26.54h-.68v4.77h-3.84v-15h6c3.1,0,5.49,1.42,5.57,4.94,0,2.77-1.13,4.35-2.95,4.91l4.08,5.15h-4.73l-3.48-4.77h.01ZM125.55,23.27c2.39,0,2.39-3.43,0-3.43h-2.23v3.43h2.23Z"/>
              <path d="M136.6,22.2h6.08v3.28h-6.08v2.24h6.61v3.58h-10.45v-15h10.45v3.57h-6.61v2.33h0Z"/>
              <path d="M157.54,31.31h-4.07l-.98-3.01h-4.01l-.93,3.01h-4.07l5.24-15h3.45l5.36,15h.01ZM151.59,24.93l-1.04-4.38h-.11l-1.1,4.38h2.25Z"/>
              <path d="M163.44,19.88v11.43h-3.84v-11.43h-3.99v-3.6h11.81v3.6h-3.99Z"/>
              <path d="M172.27,31.31h-3.79v-15h3.79v15Z"/>
              <path d="M183.06,16.28h4.07l-5.41,15h-3.48l-5.41-15h4.07l2.35,7.33.68,3.57h.06l.68-3.54,2.38-7.35Z"/>
              <path d="M186.93,21.24c0-3.45,3.09-5.15,6.21-5.15s6.23,1.72,6.23,5.15v5.13c0,3.43-3.1,5.15-6.21,5.15s-6.23-1.72-6.23-5.15v-5.13ZM190.72,26.37c0,1.1,1.23,1.63,2.44,1.63s2.41-.54,2.41-1.63v-5.13c0-1.16-1.25-1.7-2.48-1.7s-2.38.59-2.38,1.7v5.13Z"/>
            </g>
          </svg>
        </router-link>
        <NavMenuComponent @toggle-menu="handleToggleMenu"/>
      </div>
    </div>
  </header>
</template>
<script>
import { ref, onMounted, onUnmounted } from 'vue';
import NavMenuComponent from '@/components/parts/NavMenuComponent.vue';

export default {
  name: 'HeaderComponent',
  components: {
    NavMenuComponent,
  },
  setup() {
    const dropdownOpen = ref(null);
    let closeDropdownTimer = null;
    let lastScrollTop = 0;
    let headerClass = '';


    const handleToggleMenu = (isActive) => {
      if (isActive) {
        headerClass = '';
        document.body.classList.add('menu-activo');
      } else {
        headerClass = 'bg-opacity-100 visible-header';
        document.body.classList.remove('menu-activo');
      }
    };

    const openDropdown = (menu) => {
      clearTimeout(closeDropdownTimer);
      dropdownOpen.value = menu;
    };

    const startCloseDropdownTimer = () => {
      closeDropdownTimer = setTimeout(() => {
        dropdownOpen.value = null;
      }, 200); 
    };

    const cancelCloseDropdownTimer = () => {
      clearTimeout(closeDropdownTimer);
    };

    const handleScroll = () => {
      const header = document.getElementById("header");

      if (!header) {
        return;
      }

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        header.classList.remove("bg-opacity-0");
        header.classList.add("bg-opacity-100");
      } else {
        header.classList.remove("bg-opacity-100");
        header.classList.add("bg-opacity-0");
      }

      if (scrollTop > lastScrollTop) {
        header.classList.remove("visible-header");
        header.classList.add("hidden-header");
      } else {
        header.classList.remove("hidden-header");
        header.classList.add("visible-header");
      }
      lastScrollTop = scrollTop;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      dropdownOpen,
      openDropdown,
      startCloseDropdownTimer,
      cancelCloseDropdownTimer,
      handleToggleMenu,
      headerClass
    };
  }
};
</script>

<style scoped>
  .gc-header{
    height: 84px;
  }
</style>
