<template>
  <HeaderComponent />
  <main>
    <router-view />
  </main>
  <FooterComponent />
  <ButtonWhatsappComponent phoneNumber="5492302207390" message="Hola, tengo una consulta." />
  <ButtonScrollComponent   />
</template>

<script>
import HeaderComponent from './components/parts/HeaderComponent.vue';
import FooterComponent from './components/parts/FooterComponent.vue';
import ButtonWhatsappComponent from './components/buttons/ButtonWhatsappComponent.vue';
import ButtonScrollComponent from './components/buttons/ButtonScrollComponent.vue';


export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    ButtonWhatsappComponent,
    ButtonScrollComponent,
  },
  mounted() {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  },
  metaInfo: {
      title: 'Grifo Creativo - Desarrollo de software a medida. Agendá hoy tu reunión online',
      titleTemplate: '%s | Grifo Creativo'
    }
};
</script>
<style scoped>
  main{ min-height: 100vh; }
</style>