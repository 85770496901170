import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', 
    component: () => import('../views/IndexPage.vue'),
    meta: {
      title: 'Grifo Creativo - Desarrollo de software a medida. Agendá hoy tu reunión online',
      metaTags: [
        {
          name: 'description',
          content: 'Desarrollamos software a medida: CRM + WhatsApp, CRM personalizado, diseño de sitios web, desarrollo de Apps e implementación de ODOO.'
        }
      ]
    }
  },
  { path: '/desarrollo-web', component: () => import('../views/ServiceWebPage.vue') },
  { path: '/desarrollo-software', component: () => import('../views/ServiceSoftwarePage.vue') },
  { path: '/crm-personalizado', component: () => import('../views/ServiceCRMPage.vue') },
  { path: '/grifo-crm-whatsapp', component: () => import('../views/SoftwareCRMWaPage.vue') },
  { path: '/casos/mundose', component: () => import('../views/CasesMundosePage.vue') },
  { path: '/casos/coined', component: () => import('../views/CasesCoinedPage.vue') },
  { path: '/casos/nods', component: () => import('../views/CasesNodsPage.vue') },
  { path: '/casos/sixco', component: () => import('../views/CasesSixcoPage.vue') },
  { path: '/casos/municipalidad-general-pico', component: () => import('../views/CasesGralpicoPage.vue') },
  { path: '/casos-exito', component: () => import('../views/CasesPage.vue') },
  { path: '/nosotros', component: () => import('../views/AboutPage.vue') },
  { path: '/contacto', component: () => import('../views/ContactPage.vue')},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
