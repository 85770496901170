import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createMetaManager } from 'vue-meta';
// import { VueReCaptcha } from 'vue-recaptcha-v3'; // Comentar importación de reCAPTCHA
import './assets/css/tailwind.css';
import './assets/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const app = createApp(App);
const metaManager = createMetaManager();

router.afterEach((to) => {
  const body = document.querySelector('body');
  body.className = '';
  if (to.path === '/') {
    body.classList.add('homepage');
  } else if (to.path === '/contacto') {
    body.classList.add('default-page', 'bg-lightBlue');
  } else {
    body.classList.add('default-page');
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Actualizar las meta etiquetas
  const metaTags = document.querySelectorAll('meta[name="description"]');
  metaTags.forEach(tag => tag.parentNode.removeChild(tag));
  
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach(tagDef => {
      const tag = document.createElement('meta');
      tag.name = tagDef.name;
      tag.content = tagDef.content;
      document.head.appendChild(tag);
    });
  }
});

app.use(router);
app.use(metaManager);
// app.use(VueReCaptcha, { siteKey: '6Lfu7Q8TAAAAABj3Um-87TtRAe-mDqrxatmXGkX1' }); // Comentar configuración de reCAPTCHA
app.mount('#app');
